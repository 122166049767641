import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
import { Article } from 'src/app/shared/models/article';
import { Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  private articlesUrl = environment.articles.client;

  private _articles = new BehaviorSubject<Article[]>([]);
  public articles$ = this._articles.asObservable();

  private _articlesHaveLoaded = new BehaviorSubject<boolean>(false);
  public articlesHaveLoaded$ = this._articlesHaveLoaded.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.loadArticles();
  }

  private loadArticles(): void {
    let url = this.articlesUrl;
    if (isPlatformServer(this.platformId)) {
      url = environment.articles.server;
    }
    this.http
      .get<Article[]>(url)
      .pipe(
        map((articles) =>
          articles.map((article) => ({
            ...article,
            date: new Date(article.date + 'Z'),
          }))
        )
      )
      .subscribe({
        next: (articles) => this.setArticles(articles),
        error: (err) => console.error('Failed to load articles', err),
      });
  }

  public getArticlesInstant(): Article[] {
    return this._articles.value;
  }

  private setArticles(articles: Article[]): void {
    this._articles.next(articles.sort((a, b) => b.date.getTime() - a.date.getTime()));
    this._articlesHaveLoaded.next(true);
  }

  public navigateToArticle(id: string): void {
    this.router.navigate([`articles/view/${id}`]);
  }

  public copyArticleLink(id: string): void {
    const url = `${window.location.origin}/articles/view/${id}`;
    navigator.clipboard.writeText(url);
  }

  public getArticle(id: string): Observable<Article | undefined> {
    return this.articles$.pipe(map((articles) => articles.find((article) => article.id === id)));
  }

  public getArticleInstant(id: string): Article | undefined {
    return this._articles.value.find((article) => article.id === id);
  }

  public getArticleUrl(id: string): Observable<string> {
    return this.articles$.pipe(
      map((articles) => {
        const article = articles.find((article) => article.id === id);
        return article ? article.url : '';
      })
    );
  }
}
